// Owl carousel
.owl-carousel-list {
  position: relative;
  display: block;
}

.owl-caption {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 25px;
  text-align: center;

  .owl-title {
    font-size: 2.8125rem;
    font-weight: $font-weight-black;
    color: $white;

    @include media-breakpoint-down(xs) {
      font-size: 2.325rem;
    }
  }

  .owl-text {
    margin: 10px 0 50px;
    font-size: 1.125rem;
    font-weight: $font-weight-normal;
    color: rgba($white, .7);

    @include media-breakpoint-down(xs) {
      font-size: $font-size-base;
    }

    @include media-breakpoint-down(md) {
      br {
        display: none;
      }
    }
  }

  &-bottom {
    top: auto;
    bottom: 0;
    justify-content: start;
    height: auto;
    padding: 0 25px 0 0;
    text-align: left;

    .owl-title {
      padding: 15px 20px 10px 5px;
      margin: 0;
      font-size: 1.375rem;
      font-weight: $font-weight-bold;
      color: darken($headings-color, 10%);
      background-color: $body-bg;

      @include media-breakpoint-down(md) {
        padding-left: 15px;
        font-size: 1.275rem;
      }
    }
  }
}

.owl-height-100 {
  height: 100%;
  background-color: #313133;

  .owl-caption-container {
    width: 50%;

    @include media-breakpoint-down(md) {
      width: 100%;
    }
  }

  img {
    position: absolute;
    object-fit: cover;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
    opacity: .25;
  }
}

.owl-carousel-theme {
  position: relative;

  &.owl-grab {
    cursor: pointer;
  }

  .owl-dots {
    position: absolute;
    right: 0;
    bottom: 45px;
    left: 0;
    z-index: 3;
    margin: 0 auto;
    text-align: center;

    .owl-dot {
      display: inline-block;
      width: 16px;
      height: 16px;
      margin: 0 5px;
      background-color: $white;
      opacity: .5;
      @include border-radius(50%);
      @include transition($transition-base);

      &.active {
        opacity: 1;
        transform: scale(1.4);
      }
    }

    @include media-breakpoint-down(sm) {
      bottom: 25px;
    }
  }
}

// Owl nav
.owl-nav {
  button {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 10%;
    height: 100%;
    font-size: 0;
    color: $white !important;  // stylelint-disable-line declaration-no-important
    opacity: .2;

    &:hover {
      opacity: 1;
    }

    span {
      display: none;
    }
  }

  .owl-prev,
  .owl-next {
    &::after {
      font-family: $font-family-icon;
      font-size: 2.125rem;
    }
  }

  .owl-prev {
    &::after {
      content: $ya-bold-left;
    }
  }

  .owl-next {
    right: 0;
    left: auto;

    &::after {
      content: $ya-bold-right;
    }
  }

  @include media-breakpoint-down(sm) {
    display: none;
  }
}

.owl-img-md {
  height: 650px;
  background-color: $dark;
  @include img-cover;

  @include media-breakpoint-down(sm) {
    height: 500px;
  }
}

.owl-img-games {
  width: 450px;
  height: 700px;
  background-color: $dark;
  @include img-cover;

  @include media-breakpoint-down(sm) {
    height: 500px;
  }
}

.owl-width-md {
  width: 800px;

  @include media-breakpoint-down(sm) {
    width: 100%;
    margin-left: -1px;
  }
}

.owl-carousel-centered {
  .owl-stage {
    display: flex;
    align-items: center;
  }
}

// Owl Carousel - Animate Plugin
.owl-carousel{
  .animated {
    animation-duration: 1000ms;
    animation-fill-mode: both;
  }

  .owl-animated-in {
    z-index: 0;
  }

  .owl-animated-out {
    z-index: 1;
  }

  /* stylelint-disable-next-line */
  .fadeOut {
    animation-name: fadeOut;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.owl-height {
  @include transition(height 500ms ease-in-out);
}
